/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

import { getFirebaseEnvironmentVariable } from "../utils/getEnv";
import { localDev, devCloud, prodCloud } from "./firebase-configs";
// Add the Firebase products that you want to use
import "firebase/auth";

const environment = getFirebaseEnvironmentVariable();

// TODO: Remove logs after testing.
switch (environment) {
  case "local-dev":
    console.log("The environment is: ", environment);
    firebase.initializeApp(localDev);
    break;
  case "dev-cloud":
    console.log("The environment is: ", environment);
    firebase.initializeApp(devCloud);
    break;
  case "prod-cloud":
    console.log("Env: ", environment);
    firebase.initializeApp(prodCloud);
    break;

  default:
    console.log("The environment is: ", environment);
    firebase.initializeApp(localDev);
    console.log("NO ENV SET!");
    break;
}

export default firebase;
