import React from "react";

import iconCheck from "../../assets/img/icon-check.png";

import "./BulletsInformation.scss";

const BulletsInformation = () => (
  <div className="bullets-block d-flex flex-column justify-content-center align-items-start">
    <div>
      <span>
        <img src={iconCheck} alt="check-icon" />
      </span>
      <p className="bullet-text">
        Welkomstaanbieding: 10 punten wanneer je je aanmeldt
      </p>
    </div>
    <div>
      <span>
        <img src={iconCheck} alt="check-icon" />
      </span>
      <p className="bullet-text">
        Spaar punten wanneer je shopt €1,- = 1 spaarpunt
      </p>
    </div>
    <div>
      <span>
        <img src={iconCheck} alt="check-icon" />
      </span>
      <p className="bullet-text">Speciale aanbiedingen en kortingen</p>
    </div>
    <div>
      <span>
        <img src={iconCheck} alt="check-icon" />
      </span>
      <p className="bullet-text">10% korting op één item op je verjaardag</p>
    </div>
    <div>
      <span>
        <img src={iconCheck} alt="check-icon" />
      </span>
      <p className="bullet-text">Uitnodigingen voor exclusieve events</p>
    </div>
    <div>
      <span>
        <img src={iconCheck} alt="check-icon" />
      </span>
      <p className="bullet-text">Geweldige exclusieve winacties</p>
    </div>
    <div>
      <span>
        <img src={iconCheck} alt="check-icon" />
      </span>
      <p className="bullet-text">Eerder toegang tot speciale collecties</p>
    </div>
  </div>
);
export default BulletsInformation;
