import React from "react";

import { ReactComponent as StarIcon } from "../../../assets/img/Icon-awesome-star.svg";
import { ReactComponent as StoreIcon } from "../../../assets/img/Icon-awesome-store-alt.svg";
import { ReactComponent as MouseIcon } from "../../../assets/img/Icon-material-mouse.svg";
import "./SuccessResponse.scss";

const SuccessResponse = (props: {
  memberInformation: any;
  orderIdBlock: JSX.Element | null;
}) => {
  const { memberInformation, orderIdBlock } = props;

  const {
    amountOfLastPayment,
    countOfEcomPayments,
    countOfPOSPayments,
    userData,
    totalAmountOfPoints,
    orderId,
    // totalCountOfTransactions,
  } = memberInformation;

  return (
    <div id="successResponse-block">
      <h1 className="text-main-text pt-1">{`${userData?.firstName} ${userData?.lastName}`}</h1>
      {orderId && (
        <p className="fs-5 text-light text-center font-bolder m-0">
          {amountOfLastPayment} points added
        </p>
      )}
      <ul className="list-unstyled d-flex justify-content-between mt-3 pb-3 mx-auto">
        <li className="d-flex flex-column justify-content-between align-items-center text-center">
          <StoreIcon />
          <span className="d-block text-main-text font-weight-light pt-1">
            Store visits
          </span>
          <span className="w-full py-1 mt-2 bg-white border-r-5 text-main-text font-weight-bold d-block">
            {countOfPOSPayments}
          </span>
        </li>
        <li className="d-flex flex-column justify-content-between align-items-center text-center">
          <MouseIcon />
          <span className="d-block text-main-text font-weight-light pt-1">
            Webshop
          </span>
          <span className="w-full py-1 mt-2 bg-white border-r-5 text-main-text font-weight-bold d-block">
            {countOfEcomPayments}
          </span>
        </li>
        <li className="d-flex flex-column justify-content-between align-items-center text-center">
          <StarIcon />
          <span className="d-block text-main-text font-weight-light pt-1">
            Points
          </span>
          <span className="w-full py-1 mt-2 bg-white border-r-5 text-main-text font-weight-bold d-block px-2">
            {totalAmountOfPoints}
          </span>
        </li>
      </ul>

      {orderIdBlock}

      {/* <div className="bottom-block">
          <div className="col-12 row d-flex justify-content-center">
            <div className="col-10 offset-1 my-4">
              <p className="fs-5 text-center fst-italic m-0">
                This client has in total{" "}
                <span className="text-success fw-bold">
                  {totalCountOfTransactions} transactions
                </span>
                , {countOfPOSPayments} in-store, {countOfEcomPayments} online,
                and{" "}
                <span className="text-success fw-bold">
                  {totalAmountOfPoints} points{" "}
                </span>
                in total.
              </p>
            </div>
          </div>
        </div> */}
    </div>
  );
};

export default SuccessResponse;
