/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from "react";

import ClientInformation from "../components/ClientInformation/ClientInformation";
import ManualPointsButton from "../components/ManualPointsButton/ManualPointsButton";
import NavbarComponent from "../components/NavbarComponent/NavbarComponent";
import RedeemRewardButton from "../components/RedeemRewardButton/RedeemRewardButton";
import SearchBox from "../components/SearchBox/SearchBox";
import {
  usePaymentIncomingSubscription,
  useGetDataFromSearchedUserLazyQuery,
  GetPaymentIncomingData,
  useGetPaymentIncomingDataLazyQuery,
} from "../graphql/__generated__/graphql_types";

import "./HomeOverview.scss";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
};

const HomeOverviewPage = () => {
  const [userFromSearch, setUserFromSearch] = useState(initialState);
  // TODO: This is now hardcoded to their terminal from ADyen at their office.
  const [paymentIncomingData, setPaymentIncomingData] =
    useState<GetPaymentIncomingData>();

  const fakedPosId =
    new URLSearchParams(window.location.search).get("PosId") || "";

  console.log("fakedPosId", fakedPosId);

  const [selectedPosId, setSelectedPosId] = useState<string>(fakedPosId);

  const searchRef = useRef<any>(null);

  const [getPaymentIncomingData] = useGetPaymentIncomingDataLazyQuery({
    onCompleted: (data: any) => {
      console.log("data", data);
      setPaymentIncomingData(data.getPaymentIncomingData);
    },
  });

  // subscription data
  usePaymentIncomingSubscription({
    variables: {
      posId: selectedPosId,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data) {
        setPaymentIncomingData({
          status: "pending",
          amount: 0,
          countOfEcomPayments: 0,
          countOfPOSPayments: 0,
          totalCountOfTransactions: 0,
          totalAmountOfPoints: 0,
          paymentId: "",
          posId: "",
          loyyoReferenceCard: "",
          transactionId: "",
          cardId: "",
          createdAt: "",
        });

        getPaymentIncomingData({
          variables: {
            paymentId: subscriptionData?.data?.paymentIncoming?.paymentId,
          },
        });
      }
    },
  });

  const [getData] = useGetDataFromSearchedUserLazyQuery({
    onCompleted: ({ getDataFromSearchedUser }) => {
      const {
        countOfEcomPayments,
        countOfPOSPayments,
        totalCountOfTransactions,
        totalAmountOfPoints,
        user,
      } = getDataFromSearchedUser;
      setPaymentIncomingData({
        countOfEcomPayments: countOfEcomPayments || 0,
        countOfPOSPayments: countOfPOSPayments || 0,
        totalCountOfTransactions: totalCountOfTransactions || 0,
        totalAmountOfPoints: totalAmountOfPoints || 0,
        userData: user || null,
        status: "",
        amount: 0,
        paymentId: "",
        posId: "",
        loyyoReferenceCard: "",
        transactionId: "",
        cardId: "",
        createdAt: "",
      });
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const getUserData = async () => {
      if (userFromSearch?.email.length) {
        // TODO: Fill userData and create userData object
        getData({
          variables: {
            email: userFromSearch.email,
          },
        });
      }
    };
    getUserData();
  }, [getData, userFromSearch.email]);

  const selectPos = (PosId: string) => {
    setSelectedPosId(PosId);
  };

  // TODO: Check if process can be more optimized. To put state level up for example
  return (
    <>
      <div id="homeOverview">
        <NavbarComponent
          backIcon={false}
          posOption
          selectedPosId={selectedPosId}
          selectPos={selectPos}
        />

        <div className="cardBox mt-5 bg-white">
          <div className="cardBox-head">
            <ClientInformation memberInformation={paymentIncomingData} />
          </div>
          <div className="cardBox-body pb-3">
            <ul className="list-unstyled d-flex pt-4 pb-3 justify-content-center">
              <li className="herkend text-main-text">
                <span className="shape" />
                Herkend
              </li>
              <li className="terugkerend text-main-text mx-3">
                <span className="shape" />
                Terugkerend
              </li>
              <li className="nieuw text-main-text">
                <span className="shape" />
                Nieuw
              </li>
            </ul>
            <div className="btn-points-block">
              <ManualPointsButton
                enabled={
                  !!paymentIncomingData?.userData || !!userFromSearch.email
                }
                customerData={paymentIncomingData?.userData || userFromSearch}
                totalAmountOfPoints={
                  paymentIncomingData?.totalAmountOfPoints as number
                }
              />
              <RedeemRewardButton
                enabled={
                  !!paymentIncomingData?.userData || !!userFromSearch.email
                }
                customerData={paymentIncomingData?.userData || userFromSearch}
                totalAmountOfPoints={
                  paymentIncomingData?.totalAmountOfPoints as number
                }
              />
            </div>
            <div className="cardBox-body__search my-3">
              <SearchBox
                paymentId={paymentIncomingData?.paymentId}
                setUserFromSearch={setUserFromSearch}
                ref={searchRef}
                isCardAlreadyConnected={!!paymentIncomingData?.userData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeOverviewPage;
