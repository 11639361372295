import React, { ChangeEvent, FormEvent, useState } from "react";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import logo from "../assets/YOURLOGO.png";
import firebase from "../config/firebase";

import "./ForgottenPassword.scss";

const ForgottenPasswordPage = () => {
  const [email, setEmail] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    firebase.auth().languageCode = "nl";
    await firebase
      .auth()
      .sendPasswordResetEmail(email, {
        url: `https://${window.location.host}/login`,
      })
      .then(() => {
        const message = `E-mail verzonden naar ${email}!`;
        toast.success(message);
        // setResetTriggered(true);
        setEmail("");
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          const message = `E-mail is geen geldig e-mailadres!`;
          toast.error(message);
        } else if (error.code === "auth/user-not-found") {
          const message = `Dit e-mailadres bestaat niet...`;
          toast.error(message);
        } else {
          toast.error(error.message);
        }
      });
  };

  return (
    <>
      <ToastContainer />

      <div id="forgottenPage-div">
        <div className="forgotten-container container d-flex flex-column justify-content-center align-items-center">
          <div className="logo-img-block-sm d-block d-lg-none">
            <img className="logo-img" alt="logo" src={logo} />
          </div>
          <div className="main-block row">
            <div className="logo-block col-lg-6 d-lg-flex d-none justify-content-center align-items-center">
              <img alt="logo" src={logo} />
            </div>
            <div className="forgotten-block col-12 col-lg-6 d-lg-flex flex-column bg-light px-3 py-5 px-md-5">
              <div className="col-12 text-center">
                <p className="fs-3 fw-bold">Wachtwoord resetten</p>
                <p className="fs-6 text-muted">
                  Voer uw e-mailadres in om uw wachtwoord opnieuw in te stellen
                </p>
              </div>
              <div className="forgotten-component">
                <form
                  id="forgotten-form"
                  onSubmit={handleSubmit}
                  className="bg-light d-flex flex-column align-items-stretch justify-content-evenly"
                >
                  <div className="input-group has-validation">
                    <span className="input-group-text" id="inputGroupPassword">
                      <i className="fa fa-solid fa-envelope" />
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="johnsmith@example.com"
                      name="emailAddress"
                      value={email}
                      onChange={handleInputChange}
                    />
                    <div className="invalid-feedback" />
                  </div>

                  <div className="forgotten-btn-block col-12 col-md-10 offset-md-1">
                    <button
                      type="submit"
                      className="forgotten-btn btn btn-main col-12 col-lg-8"
                    >
                      Submit
                    </button>
                    <Link
                      className="login-btn text-muted text-decoration-none text-nowrap"
                      to="/login"
                    >
                      Of klik hier om in te loggen
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgottenPasswordPage;
