import React from "react";

import { FaRegAddressCard } from "react-icons/fa";
import Modal from "react-modal";
import { toast } from "react-toastify";

import {
  useConnectCardToUserMutation,
  User,
} from "../../graphql/__generated__/graphql_types";

import "./ConnectModal.scss";

const ConnectModal = (props: {
  isOpen: boolean;
  closeModal: () => void;
  userData: User | undefined;
  paymentId: string | undefined | null;
  reset: () => void;
}) => {
  const { isOpen, closeModal, userData, paymentId, reset } = props;
  const [connectCard] = useConnectCardToUserMutation();

  const connectCardWithUser = async () => {
    await connectCard({
      variables: {
        paymentId: paymentId as string,
        userEmail: userData?.email as string,
      },
    }).then(
      () => {
        closeModal();
        toast.success("Koppeling pas is gelukt");
        reset();
      },
      () => {
        toast.error("Telefoonnummer is niet valide.");
        closeModal();
      }
    );
  };

  // TODO: When connecting, send email to verify user. Now it is connected straight away.

  Modal.setAppElement("#root");
  return (
    <Modal
      id="connectModal"
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={closeModal}
      className="col-12 d-flex justify-content-center align-items-center"
      style={{ overlay: { background: "rgba(0,0,0, 0.5)", zIndex: 11 } }}
    >
      <div className="rounded-3 col-11 col-md-10 col-lg-8">
        <div className="top-block col-12 d-flex justify-content-center align-items-center flex-column">
          <div className="information-icon-block">
            <div className="information-icon rounded-circle">
              <FaRegAddressCard color="white" size={30} />
            </div>
          </div>
          <div className="title text-center d-flex justify-content-center align-items-center">
            <p className="m-0">Koppel kaart aan gebruiker</p>
          </div>
        </div>
        <div className="bottom-block text-center">
          <div className="py-5">
            U staat op het punt om de kaart van de laatste betaling te koppelen
            aan:
            <div className="fw-bold my-3">
              <div>{`${userData?.firstName} ${userData?.lastName}`}</div>
              <div>{userData?.phoneNumber}</div>
              <div>{userData?.email}</div>
            </div>
            <div className="">
              The card will be connected to the user and this payment earned
              points will be added to the user.
            </div>
          </div>

          <div className="pb-5 col-12 d-flex justify-content-center align-items-center">
            <button
              className="cancel-btn mx-2"
              type="button"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="confirm-btn mx-2"
              type="button"
              onClick={connectCardWithUser}
            >
              Bevestig
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectModal;
