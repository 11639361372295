import React from "react";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useGetRewardListQuery,
  useUserRedeemRewardMutation,
} from "../../graphql/__generated__/graphql_types";
import Loading from "../Loading/Loading";
import RewardItem from "./RewardItem";

const Rewards = () => {
  const { state } = useHistory().location as any;
  const [redeemReward, { loading, error }] = useUserRedeemRewardMutation();

  const handleRedeemSubmit = async (email: string, rewardId:string): Promise<boolean> => {
    const result = await redeemReward({
      variables: {
        email,
        rewardId,
      },
    });

    if (error) {
      toast.error(`something went wrong try it again`);
      return false;
    }

    if (result.data?.userRedeemReward.status) {
      toast.success(`redeem successfully`);
      setTimeout(() => {
        window.location.assign("/interface/redeem-rewards");
      }, 2000);
    } else {
      toast.error(`${JSON.stringify(result.data?.userRedeemReward.message)}`);
    }

    console.log("result", result);
    return true;
  };

  const { data, loading: loadingRewards } = useGetRewardListQuery({
    variables: {
      email: state.customerData?.email,
    },
  });

  const rewards = data?.getRewardList || [];

  return loading || loadingRewards ? (
    <div className="h-100">
      <Loading />
    </div>
  ) : (
    <div id="reward">
      <div style={{ marginBottom: "13%" }}>
        <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
          {rewards.map((reward: any) => (
            <RewardItem
              key={reward.id}
              rewardId={reward.id}
              email={state.customerData?.email}
              name={reward.title || ""}
              description={reward.description || ""}
              imageUrl={reward.imageUrl || ""}
              redeemable={reward.redeemable}
              redeemedAt = {reward.redeemedAt}
              handleRedeemSubmit={handleRedeemSubmit}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rewards;
