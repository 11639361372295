import React, { useState } from "react";

import "./RewardItem.scss";
import YesnoModal from "../Modal/YesnoModal";
import Redeemed from "../Redeemed/Redeemed";
// import RedeemPointsNotEnough from "../RedeemPointsNotEnough/RedeemPointsNotEnough";

const RewardItem = (props: {
  email: string;
  rewardId: string;
  imageUrl: string;
  name: string;
  description: string;
  redeemable: boolean;
  redeemedAt: Date | null;
  handleRedeemSubmit: (email: string, rewardId: string) => Promise<boolean>;
}) => {
  const {
    email,
    rewardId,
    imageUrl,
    name,
    description,
    redeemable,
    redeemedAt,
    handleRedeemSubmit,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const displayName = name;

  return (
    <div className="reward-card card-hover">
      <div className="img_div" style={{ width: "80%", height: "50%" }}>
        <p style={{ display: "none", fontSize: 12 }}>
          <button
            onClick={() => setIsModalOpen(true)}
            type="button"
            className="btn mt-2"
          >
            Redeem
          </button>
        </p>
        <img
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 7,
            objectFit: "contain",
          }}
          src={imageUrl}
          alt="card1"
        />
      </div>
      <span
        className="w-75 text-main-text font-weight-light pt-1 text-center"
        style={{ fontSize: 14, marginTop: 5, textAlign: "center" }}
      >
        {displayName}
      </span>
      <span style={{ fontSize: 12, color: "#c4d11f" }}>{description}</span>
      {!redeemable && redeemedAt && <Redeemed />}
      {/* {!redeemable && !redeemedAt && <RedeemPointsNotEnough />} */}

      <YesnoModal
        isOpen={isModalOpen}
        title="Are you sure?"
        body={`Are you sure you want to redeem ${displayName}?`}
        btnYes="Yes"
        btnNo="No"
        setIsModalOpen={setIsModalOpen}
        handleYes={() => handleRedeemSubmit(email, rewardId)}
      />
    </div>
  );
};
export default RewardItem;
