import React from "react";

import {
  Route,
  BrowserRouter as Router,
  Switch,
  // Redirect,
} from "react-router-dom";

import AdminRouteGuard from "./components/RouteGuard/AdminRouteGuard";
import RouteGuard from "./components/RouteGuard/RouteGuard";
import AddPointsPage from "./pages/AddPoints";
// import CardConnectedPage from "./pages/CardConnectedPage";
import ForgottenPasswordPage from "./pages/ForgottenPassword";
import HomeOverviewPage from "./pages/HomeOverview";
import InterfaceSearchPage from "./pages/InterfaceSearchPage";
import LoginPage from "./pages/LoginPage";
// import MemberOverviewPage from "./pages/MemberOverViewPage";
import PageNotFound from "./pages/PageNotFound";
import RedeemPointsPage from "./pages/RedeemPoints";
import RedeemRewardsPage from "./pages/RedeemRewardsPage";
// import RegisterPage from "./pages/RegisterPage";

const Maintenance = () => (
  <div
    style={{
      display: "table",
      margin: "0 auto",
      height: "100vh",
      marginTop: "25%",
      maxWidth: "500px",
      textAlign: "center",
    }}
  >
    <div>
      <h4>Dear Customer / Beste klant</h4>

      <div>
        <p>
          We are currently working on a new version of the website to improve
          your member experience. If you are an existing member, expect an email
          with further news soon. If you want to signup as a new member, please
          try this website later.
        </p>

        <p>
          Momenteel werken wij aan een nieuwe versie van de website om deze te
          verbeteren uw ledenervaring. Als u een bestaand lid bent, kunt u een
          e-mail verwachten met binnenkort meer nieuws. Als u zich wilt
          aanmelden als nieuw lid, alstublieft probeer deze website later eens.
        </p>
      </div>
    </div>
  </div>
);

const Routes = () => (
  <Router>
    <Switch>
      <RouteGuard exact path="/">
        {/* <Redirect to="/member" /> */}
        <Maintenance />
      </RouteGuard>
      <AdminRouteGuard exact path="/interface">
        <HomeOverviewPage />
      </AdminRouteGuard>
      <AdminRouteGuard exact path="/interface/add-points">
        <AddPointsPage />
      </AdminRouteGuard>
      <AdminRouteGuard exact path="/interface/redeem-points">
        <RedeemPointsPage />
      </AdminRouteGuard>
      <AdminRouteGuard exact path="/interface/redeem-rewards">
        <RedeemRewardsPage />
      </AdminRouteGuard>
      <AdminRouteGuard exact path="/interface/search">
        <InterfaceSearchPage />
      </AdminRouteGuard>
      <RouteGuard exact path="/member">
        {/* <MemberOverviewPage /> */}
        <Maintenance />
      </RouteGuard>
      <Route exact path="/login">
        <LoginPage />
      </Route>
      <Route exact path="/reset-password">
        <ForgottenPasswordPage />
      </Route>
      <Route exact path="/register">
        {/* <RegisterPage /> */}
        <Maintenance />
      </Route>
      <Route exact path="/register/p=:paymentId">
        {/* <RegisterPage /> */}
        <Maintenance />
      </Route>
      <Route exact path="/card-connected">
        {/* <CardConnectedPage /> */}
        <Maintenance />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  </Router>
);

export default Routes;
