import React, { Dispatch, SetStateAction } from "react";

import Modal from "react-modal";
import "./YesnoModal.scss";

function YesnoModal(props: {
  isOpen: boolean;
  title: string;
  body: string;
  btnYes: string | undefined;
  btnNo: string | undefined;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  handleYes: any;
}) {
  const { isOpen, title, body, btnYes, btnNo, setIsModalOpen, handleYes } =
    props;

  Modal.setAppElement("#root");
  return (
    <Modal
      id="yesnoModal"
      isOpen={isOpen}
      className="col-12 d-flex justify-content-center align-items-center"
      style={{ overlay: { background: "rgba(0,0,0, 0.5)", zIndex: 2000 } }}
    >
      <div
        className="rounded-3 col-11 col-md-10 col-lg-8 p-5"
        style={{ background: "#f2f4f6" }}
      >
        <div className="">
          <h5 className="modal-title text-center">{title}</h5>
        </div>
        <div className="modal-body text-center">{body}</div>
        <div className="p-3 col-12 d-flex justify-content-center align-items-center">
          <button
            type="button"
            className="btn btn-secondary me-3"
            onClick={() => setIsModalOpen(false)}
          >
            {btnNo}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              handleYes();
              setIsModalOpen(false);
            }}
          >
            {btnYes}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default YesnoModal;
