import React, { useState } from "react";

import { Navbar, Nav, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
// import { toast } from "react-toastify";

import { ReactComponent as ResetIcon } from "../../assets/img/Icon-open-reload.svg";
// import { ReactComponent as Line } from "../../assets/img/Line-1.svg";
// import { ReactComponent as Logo } from "../../assets/img/LOYYO-logo-landscape-black.svg";
import logo from "../../assets/logo.png";
import "./NavbarComponent.scss";
import firebase from "../../config/firebase";
import { useGetPosListLazyQuery } from "../../graphql/__generated__/graphql_types";

const NavbarComponet = ({
  backIcon,
  posOption,
  selectedPosId,
  selectPos,
}: {
  backIcon: boolean;
  posOption: boolean;
  selectedPosId?: string;
  selectPos?: (posId: string) => void;
}) => {
  const [posList, setPosList] = useState<{ name: string; posId: string }[]>([]);

  // 放入變數
  const [getPosList, { data, loading }] = useGetPosListLazyQuery();

  if (posList.length === 0)
    firebase.auth().onAuthStateChanged(async (userAuth) => {
      const adminEmail = userAuth?.email || "";

      getPosList({
        variables: {
          adminEmail,
        },
      });

      if (data && !loading) {
        if (data.getPosList.length !== posList.length) {
          setPosList(
            data.getPosList.map((pos) => ({
              name: pos.name,
              posId: pos.posId,
            }))
          );
          if (selectPos && data?.getPosList?.length === 1 ) {
            selectPos(data.getPosList[0].posId);
          }
        }
      }
    });

  console.log("selectedPosId", selectedPosId);

  const handleChange = (selected: any) => {
    selectPos!(selected.posId);
  };

  const isClearable: boolean = false;
  const isSearchable: boolean = false;
  const iff = (condition: boolean, then: any, otherwise: any) =>
    condition ? then : otherwise;

  const colourStyles = {
    // color: "red",
    control: (styles: any, { isFocused, isSelected }: any) => ({
      ...styles,
      padding: "0 8px",
      //   backgroundColor: "#fff",
      borderColor: isFocused || isSelected ? "#fff" : "#fff",

      boxShadow: "rgba(0, 0, 0, 0.16) 3px 3px 6px 0px",
      color: "#110B3D",
      paddingRight: 0,
      //   maxWidth: "200px"
      fontSize: 18,
      height: 50,
      width: 230,
      marginLeft: "auto",
    }),
    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
      ...styles,
      color: "#110B3D",
      backgroundColor: iff(
        isDisabled,
        null,
        iff(isSelected, "#fff", isFocused)
      ),
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && "#110B3D",
      },
      ":hover": {
        //   ...styles[":active"],
        backgroundColor: !isDisabled && "#110b3d41",
      },
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "#110B3D",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "#110B3D",
    }),
    menuList: (styles: any) => ({
      ...styles,
      color: "#110B3D",
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: "none",
    }),
    dropdownIndicator: (styles: any, { isFocused }: any) => ({
      ...styles,
      color: isFocused ? "#110B3D" : "#110B3D",
      ":hover": {
        ...styles[":active"],
        color: "#110B3D",
      },
    }),
  };

  const refreshInfo = () => {
    window.location.assign("/interface");
    console.log("backIcon", backIcon);
  };

  return (
    <Navbar
      id="Navbar-initialState"
      expand="lg"

      // style={{
      //   background: `url(${NavBg})`,
      //   backgroundSize: "cover"
      // }}
    >
      {/* <img className="position-absolute bg-graphic" src={NavBg} alt="bg" /> */}
      <Container className="w-100 d-flex justify-content-between align-items-center">
        <Link to="/">
          <Button
            variant="d"
            className="border-0 text-main-text btn-reset text-uppercase"
            onClick={refreshInfo}
          >
            <span className="bg-white d-inline-block p-2 border-r-5 me-3 shadow-b">
              <ResetIcon />
            </span>
            <span className="d-none d-sm-inline-block ">Restart</span>
          </Button>
        </Link>
        <Navbar.Brand className="mx-auto position-sm-static position-absolute">
          <Link to="/">
            <img className="logo" alt="logo" src={logo} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {posOption && (
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end pe-4"
          >
            <Nav className="d-flex flex-row justify-content-evenly">
              <Select
                styles={colourStyles}
                placeholder="select"
                classNamePrefix="select"
                defaultValue={
                  selectedPosId
                    ? posList.find(
                        (pos) => pos.posId === selectedPosId
                      )
                    : { name: "select", posId: "" }
                }
                value={
                  selectedPosId
                    ? posList.find(
                        (pos) => pos.posId === selectedPosId
                      )
                    : { name: "select", posId: "" }
                }
                // defaultValue={{ name: "", posId: selectedPosId || "" }}
                // isDisabled={isDisabled}
                // isLoading={isLoading}
                isClearable={isClearable}
                //   isRtl={isRtl}
                isSearchable={isSearchable}
                name="member"
                options={posList}
                getOptionLabel={(pos: { name: string }): any => (
                  <span>{pos?.name}</span>
                )}
                getOptionValue={(pos: { name: string; posId: string }) =>
                  pos?.posId
                }
                onChange={handleChange}
              />
            </Nav>
          </Navbar.Collapse>
        )}
        {/* <span className="bg-white d-none d-lg-inline-flex justify-content-center align-items-center p-2 border-r-5 shadow-b nav-ico c-pointer">
          <span className="d-block">
            <span className="d-flex">
              <Line />
            </span>
            <span className="d-flex">
              <Line />
            </span>
            <span className="d-flex">
              <Line />
            </span>
          </span>
        </span> */}
      </Container>
    </Navbar>
  );
};

NavbarComponet.defaultProps = {
  selectedPosId: undefined,
  selectPos: undefined,
};

export default NavbarComponet;
