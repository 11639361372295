import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AddManualPointsByEuroResponse = {
  __typename?: 'AddManualPointsByEuroResponse';
  amountOfPoints: Scalars['Int'];
  amountInCents: Scalars['Int'];
  id: Scalars['String'];
};

export type AddPointsByEuroInput = {
  amountInCents: Scalars['String'];
  email: Scalars['String'];
};

export type AddPointsInput = {
  amount: Scalars['String'];
  email: Scalars['String'];
};

export type AddPointsInputForProductRedeem = {
  amount: Scalars['String'];
  email: Scalars['String'];
};

export type ConnectCardToUserInput = {
  userEmail: Scalars['String'];
  paymentId: Scalars['String'];
};

export type ConnectCardToUserInputResponse = {
  __typename?: 'ConnectCardToUserInputResponse';
  status: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  phoneNumber: Scalars['String'];
  phoneNumberCountryCode: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  cardAlias?: Maybe<Scalars['String']>;
  termsAgreed: Scalars['Boolean'];
  paymentId?: Maybe<Scalars['String']>;
};

export type DataFromSearchedUserResponse = {
  __typename?: 'DataFromSearchedUserResponse';
  amountOfLastPayment?: Maybe<Scalars['Int']>;
  countOfEcomPayments?: Maybe<Scalars['Int']>;
  countOfPOSPayments?: Maybe<Scalars['Int']>;
  totalCountOfTransactions?: Maybe<Scalars['Int']>;
  totalAmountOfPoints?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  isCardRecognized?: Maybe<Scalars['Boolean']>;
  isCardConnectedToUser?: Maybe<Scalars['Boolean']>;
  orderId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  customerKey?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  terminalId?: Maybe<Scalars['String']>;
};


export type FindUniqueCardResponse = {
  __typename?: 'FindUniqueCardResponse';
  status: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type GetPaymentIncomingData = {
  __typename?: 'GetPaymentIncomingData';
  countOfEcomPayments: Scalars['Int'];
  countOfPOSPayments: Scalars['Int'];
  totalCountOfTransactions: Scalars['Int'];
  totalAmountOfPoints: Scalars['Int'];
  status: Scalars['String'];
  paymentId: Scalars['String'];
  amount: Scalars['Float'];
  posId: Scalars['String'];
  loyyoReferenceCard: Scalars['String'];
  transactionId: Scalars['String'];
  cardId: Scalars['String'];
  createdAt: Scalars['String'];
  userData?: Maybe<User>;
};

export type GetPosListResponse = {
  __typename?: 'GetPosListResponse';
  id: Scalars['String'];
  posId: Scalars['String'];
  name: Scalars['String'];
};

export type GetRewardList = {
  __typename?: 'GetRewardList';
  id: Scalars['String'];
  label: Scalars['String'];
  pointCost: Scalars['Float'];
  imageUrl: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  isRedeemed: Scalars['Boolean'];
  redeemedAt?: Maybe<Scalars['DateTime']>;
  redeemable: Scalars['Boolean'];
};

export enum Language {
  Dutch = 'Dutch',
  English = 'English',
  German = 'German'
}

export type Mutation = {
  __typename?: 'Mutation';
  sendRegisterSMS: SmsPayload;
  connectCardToUser: ConnectCardToUserInputResponse;
  userRedeemReward: UserRedeemReward;
  createUser: User;
  deleteUser: User;
  updateUserAccountData: UserAccountDataUpdated;
  addManualPointsByEuro: AddManualPointsByEuroResponse;
  redeemPointsManually: RedeemedPoints;
  redeemPointsForProduct: RedeemedPoints;
};


export type MutationSendRegisterSmsArgs = {
  sendRegisterSMSInput: SendRegisterSmsInput;
};


export type MutationConnectCardToUserArgs = {
  connectCardToUserInput: ConnectCardToUserInput;
};


export type MutationUserRedeemRewardArgs = {
  rewardId: Scalars['String'];
  email: Scalars['String'];
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationDeleteUserArgs = {
  userEmail: Scalars['String'];
};


export type MutationUpdateUserAccountDataArgs = {
  updateUserAccountInput: UpdateUserAccountInput;
  userEmailFromClient: Scalars['String'];
};


export type MutationAddManualPointsByEuroArgs = {
  addPointsByEuroInput: AddPointsByEuroInput;
};


export type MutationRedeemPointsManuallyArgs = {
  addPointsInput: AddPointsInput;
};


export type MutationRedeemPointsForProductArgs = {
  redeemPoints: AddPointsInputForProductRedeem;
};

export type PaymentIncoming = {
  __typename?: 'PaymentIncoming';
  paymentId: Scalars['String'];
  posId: Scalars['String'];
  createdAt: Scalars['String'];
};

export type Points = {
  __typename?: 'Points';
  amount: Scalars['Int'];
  id: Scalars['String'];
  validFrom: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  findUniqueCard: FindUniqueCardResponse;
  getRewardList: Array<GetRewardList>;
  getPosList: Array<GetPosListResponse>;
  getPaymentIncomingData: GetPaymentIncomingData;
  searchFilterUsers: Array<User>;
  verifyUserToken: User;
  getDataFromSearchedUser: DataFromSearchedUserResponse;
  getUserOverviewData: UserWithPoints;
};


export type QueryFindUniqueCardArgs = {
  paymentId: Scalars['String'];
};


export type QueryGetRewardListArgs = {
  email: Scalars['String'];
};


export type QueryGetPosListArgs = {
  adminEmail?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentIncomingDataArgs = {
  paymentId: Scalars['String'];
};


export type QuerySearchFilterUsersArgs = {
  filter?: Maybe<Scalars['String']>;
};


export type QueryVerifyUserTokenArgs = {
  email: Scalars['String'];
};


export type QueryGetDataFromSearchedUserArgs = {
  email: Scalars['String'];
};


export type QueryGetUserOverviewDataArgs = {
  email: Scalars['String'];
};

export type RedeemedPoints = {
  __typename?: 'RedeemedPoints';
  id: Scalars['String'];
  amount: Scalars['Float'];
};

export enum Role {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type SmsPayload = {
  __typename?: 'SMSPayload';
  smsId: Scalars['String'];
};

export type SendRegisterSmsInput = {
  to: Scalars['String'];
  paymentId?: Maybe<Scalars['String']>;
  phoneNumberCountryCode?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  paymentIncoming: PaymentIncoming;
};


export type SubscriptionPaymentIncomingArgs = {
  posId: Scalars['String'];
};

export type UpdateUserAccountInput = {
  phoneNumber: Scalars['String'];
  language: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  language?: Maybe<Language>;
  Role?: Maybe<Role>;
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  isTokenValid?: Maybe<Scalars['Boolean']>;
};

export type UserAccountDataUpdated = {
  __typename?: 'UserAccountDataUpdated';
  language?: Maybe<Language>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  isEmailChanged?: Maybe<Scalars['Boolean']>;
};

export type UserRedeemReward = {
  __typename?: 'UserRedeemReward';
  status: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type UserWithPoints = {
  __typename?: 'UserWithPoints';
  language?: Maybe<Language>;
  totalAmountOfPoints: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  Points: Array<Points>;
  phoneNumber: Scalars['String'];
};

export type FindUniqueCardQueryVariables = Exact<{
  paymentId: Scalars['String'];
}>;


export type FindUniqueCardQuery = (
  { __typename?: 'Query' }
  & { findUniqueCard: (
    { __typename?: 'FindUniqueCardResponse' }
    & Pick<FindUniqueCardResponse, 'status' | 'phoneNumber'>
  ) }
);

export type ConnectCardToUserMutationVariables = Exact<{
  userEmail: Scalars['String'];
  paymentId: Scalars['String'];
}>;


export type ConnectCardToUserMutation = (
  { __typename?: 'Mutation' }
  & { connectCardToUser: (
    { __typename?: 'ConnectCardToUserInputResponse' }
    & Pick<ConnectCardToUserInputResponse, 'status' | 'message'>
  ) }
);

export type PaymentIncomingSubscriptionVariables = Exact<{
  posId: Scalars['String'];
}>;


export type PaymentIncomingSubscription = (
  { __typename?: 'Subscription' }
  & { paymentIncoming: (
    { __typename?: 'PaymentIncoming' }
    & Pick<PaymentIncoming, 'posId' | 'paymentId' | 'createdAt'>
  ) }
);

export type GetPaymentIncomingDataQueryVariables = Exact<{
  paymentId: Scalars['String'];
}>;


export type GetPaymentIncomingDataQuery = (
  { __typename?: 'Query' }
  & { getPaymentIncomingData: (
    { __typename?: 'GetPaymentIncomingData' }
    & Pick<GetPaymentIncomingData, 'status' | 'paymentId' | 'amount' | 'loyyoReferenceCard' | 'transactionId' | 'cardId' | 'createdAt' | 'countOfEcomPayments' | 'countOfPOSPayments' | 'totalCountOfTransactions' | 'totalAmountOfPoints'>
    & { userData?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'lastName' | 'firstName'>
    )> }
  ) }
);

export type AddManualPointsByEuroMutationVariables = Exact<{
  amountInCents: Scalars['String'];
  email: Scalars['String'];
}>;


export type AddManualPointsByEuroMutation = (
  { __typename?: 'Mutation' }
  & { addManualPointsByEuro: (
    { __typename?: 'AddManualPointsByEuroResponse' }
    & Pick<AddManualPointsByEuroResponse, 'id' | 'amountOfPoints' | 'amountInCents'>
  ) }
);

export type RedeemPointsManuallyMutationVariables = Exact<{
  amount: Scalars['String'];
  email: Scalars['String'];
}>;


export type RedeemPointsManuallyMutation = (
  { __typename?: 'Mutation' }
  & { redeemPointsManually: (
    { __typename?: 'RedeemedPoints' }
    & Pick<RedeemedPoints, 'id' | 'amount'>
  ) }
);

export type RedeemPointsForProductMutationVariables = Exact<{
  amount: Scalars['String'];
  email: Scalars['String'];
}>;


export type RedeemPointsForProductMutation = (
  { __typename?: 'Mutation' }
  & { redeemPointsForProduct: (
    { __typename?: 'RedeemedPoints' }
    & Pick<RedeemedPoints, 'id' | 'amount'>
  ) }
);

export type GetRewardListQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetRewardListQuery = (
  { __typename?: 'Query' }
  & { getRewardList: Array<(
    { __typename?: 'GetRewardList' }
    & Pick<GetRewardList, 'id' | 'label' | 'title' | 'description' | 'imageUrl' | 'pointCost' | 'isRedeemed' | 'redeemedAt' | 'redeemable'>
  )> }
);

export type UserRedeemRewardMutationVariables = Exact<{
  email: Scalars['String'];
  rewardId: Scalars['String'];
}>;


export type UserRedeemRewardMutation = (
  { __typename?: 'Mutation' }
  & { userRedeemReward: (
    { __typename?: 'UserRedeemReward' }
    & Pick<UserRedeemReward, 'status' | 'message'>
  ) }
);

export type SearchUsersQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
}>;


export type SearchUsersQuery = (
  { __typename?: 'Query' }
  & { searchFilterUsers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'phoneNumber' | 'firstName' | 'lastName'>
  )> }
);

export type RegisterUserMutationVariables = Exact<{
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  phoneNumberCountryCode: Scalars['String'];
  termsAgreed: Scalars['Boolean'];
  customerKey?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'firstName'>
  ) }
);

export type SendRegisterSmsMutationVariables = Exact<{
  to: Scalars['String'];
  paymentId?: Maybe<Scalars['String']>;
  phoneNumberCountryCode?: Maybe<Scalars['String']>;
}>;


export type SendRegisterSmsMutation = (
  { __typename?: 'Mutation' }
  & { sendRegisterSMS: (
    { __typename?: 'SMSPayload' }
    & Pick<SmsPayload, 'smsId'>
  ) }
);

export type CheckTokenQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckTokenQuery = (
  { __typename?: 'Query' }
  & { verifyUserToken: (
    { __typename?: 'User' }
    & Pick<User, 'isTokenValid' | 'Role'>
  ) }
);

export type GetDataFromSearchedUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetDataFromSearchedUserQuery = (
  { __typename?: 'Query' }
  & { getDataFromSearchedUser: (
    { __typename?: 'DataFromSearchedUserResponse' }
    & Pick<DataFromSearchedUserResponse, 'countOfEcomPayments' | 'countOfPOSPayments' | 'totalCountOfTransactions' | 'totalAmountOfPoints'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>
    )> }
  ) }
);

export type GetUserOverviewDataQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUserOverviewDataQuery = (
  { __typename?: 'Query' }
  & { getUserOverviewData: (
    { __typename?: 'UserWithPoints' }
    & Pick<UserWithPoints, 'firstName' | 'lastName' | 'phoneNumber' | 'totalAmountOfPoints' | 'language'>
    & { Points: Array<(
      { __typename?: 'Points' }
      & Pick<Points, 'id' | 'amount' | 'validFrom' | 'createdAt'>
    )> }
  ) }
);

export type UpdateUserAccountMutationVariables = Exact<{
  updateUserAccountInput: UpdateUserAccountInput;
  userEmailFromClient: Scalars['String'];
}>;


export type UpdateUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAccountData: (
    { __typename?: 'UserAccountDataUpdated' }
    & Pick<UserAccountDataUpdated, 'email' | 'firstName' | 'isEmailChanged' | 'language'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  userEmail: Scalars['String'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'User' }
    & Pick<User, 'email'>
  ) }
);

export type GetPosListQueryVariables = Exact<{
  adminEmail?: Maybe<Scalars['String']>;
}>;


export type GetPosListQuery = (
  { __typename?: 'Query' }
  & { getPosList: Array<(
    { __typename?: 'GetPosListResponse' }
    & Pick<GetPosListResponse, 'posId' | 'name'>
  )> }
);


export const FindUniqueCardDocument = gql`
    query findUniqueCard($paymentId: String!) {
  findUniqueCard(paymentId: $paymentId) {
    status
    phoneNumber
  }
}
    `;

/**
 * __useFindUniqueCardQuery__
 *
 * To run a query within a React component, call `useFindUniqueCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueCardQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useFindUniqueCardQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueCardQuery, FindUniqueCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueCardQuery, FindUniqueCardQueryVariables>(FindUniqueCardDocument, options);
      }
export function useFindUniqueCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueCardQuery, FindUniqueCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueCardQuery, FindUniqueCardQueryVariables>(FindUniqueCardDocument, options);
        }
export type FindUniqueCardQueryHookResult = ReturnType<typeof useFindUniqueCardQuery>;
export type FindUniqueCardLazyQueryHookResult = ReturnType<typeof useFindUniqueCardLazyQuery>;
export type FindUniqueCardQueryResult = Apollo.QueryResult<FindUniqueCardQuery, FindUniqueCardQueryVariables>;
export const ConnectCardToUserDocument = gql`
    mutation connectCardToUser($userEmail: String!, $paymentId: String!) {
  connectCardToUser(
    connectCardToUserInput: {userEmail: $userEmail, paymentId: $paymentId}
  ) {
    status
    message
  }
}
    `;
export type ConnectCardToUserMutationFn = Apollo.MutationFunction<ConnectCardToUserMutation, ConnectCardToUserMutationVariables>;

/**
 * __useConnectCardToUserMutation__
 *
 * To run a mutation, you first call `useConnectCardToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectCardToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectCardToUserMutation, { data, loading, error }] = useConnectCardToUserMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useConnectCardToUserMutation(baseOptions?: Apollo.MutationHookOptions<ConnectCardToUserMutation, ConnectCardToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectCardToUserMutation, ConnectCardToUserMutationVariables>(ConnectCardToUserDocument, options);
      }
export type ConnectCardToUserMutationHookResult = ReturnType<typeof useConnectCardToUserMutation>;
export type ConnectCardToUserMutationResult = Apollo.MutationResult<ConnectCardToUserMutation>;
export type ConnectCardToUserMutationOptions = Apollo.BaseMutationOptions<ConnectCardToUserMutation, ConnectCardToUserMutationVariables>;
export const PaymentIncomingDocument = gql`
    subscription PaymentIncoming($posId: String!) {
  paymentIncoming(posId: $posId) {
    posId
    paymentId
    createdAt
  }
}
    `;

/**
 * __usePaymentIncomingSubscription__
 *
 * To run a query within a React component, call `usePaymentIncomingSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePaymentIncomingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentIncomingSubscription({
 *   variables: {
 *      posId: // value for 'posId'
 *   },
 * });
 */
export function usePaymentIncomingSubscription(baseOptions: Apollo.SubscriptionHookOptions<PaymentIncomingSubscription, PaymentIncomingSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PaymentIncomingSubscription, PaymentIncomingSubscriptionVariables>(PaymentIncomingDocument, options);
      }
export type PaymentIncomingSubscriptionHookResult = ReturnType<typeof usePaymentIncomingSubscription>;
export type PaymentIncomingSubscriptionResult = Apollo.SubscriptionResult<PaymentIncomingSubscription>;
export const GetPaymentIncomingDataDocument = gql`
    query getPaymentIncomingData($paymentId: String!) {
  getPaymentIncomingData(paymentId: $paymentId) {
    status
    paymentId
    amount
    loyyoReferenceCard
    transactionId
    cardId
    createdAt
    userData {
      email
      lastName
      firstName
    }
    countOfEcomPayments
    countOfPOSPayments
    totalCountOfTransactions
    totalAmountOfPoints
  }
}
    `;

/**
 * __useGetPaymentIncomingDataQuery__
 *
 * To run a query within a React component, call `useGetPaymentIncomingDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentIncomingDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentIncomingDataQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useGetPaymentIncomingDataQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentIncomingDataQuery, GetPaymentIncomingDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentIncomingDataQuery, GetPaymentIncomingDataQueryVariables>(GetPaymentIncomingDataDocument, options);
      }
export function useGetPaymentIncomingDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentIncomingDataQuery, GetPaymentIncomingDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentIncomingDataQuery, GetPaymentIncomingDataQueryVariables>(GetPaymentIncomingDataDocument, options);
        }
export type GetPaymentIncomingDataQueryHookResult = ReturnType<typeof useGetPaymentIncomingDataQuery>;
export type GetPaymentIncomingDataLazyQueryHookResult = ReturnType<typeof useGetPaymentIncomingDataLazyQuery>;
export type GetPaymentIncomingDataQueryResult = Apollo.QueryResult<GetPaymentIncomingDataQuery, GetPaymentIncomingDataQueryVariables>;
export const AddManualPointsByEuroDocument = gql`
    mutation addManualPointsByEuro($amountInCents: String!, $email: String!) {
  addManualPointsByEuro(
    addPointsByEuroInput: {amountInCents: $amountInCents, email: $email}
  ) {
    id
    amountOfPoints
    amountInCents
  }
}
    `;
export type AddManualPointsByEuroMutationFn = Apollo.MutationFunction<AddManualPointsByEuroMutation, AddManualPointsByEuroMutationVariables>;

/**
 * __useAddManualPointsByEuroMutation__
 *
 * To run a mutation, you first call `useAddManualPointsByEuroMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManualPointsByEuroMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManualPointsByEuroMutation, { data, loading, error }] = useAddManualPointsByEuroMutation({
 *   variables: {
 *      amountInCents: // value for 'amountInCents'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddManualPointsByEuroMutation(baseOptions?: Apollo.MutationHookOptions<AddManualPointsByEuroMutation, AddManualPointsByEuroMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddManualPointsByEuroMutation, AddManualPointsByEuroMutationVariables>(AddManualPointsByEuroDocument, options);
      }
export type AddManualPointsByEuroMutationHookResult = ReturnType<typeof useAddManualPointsByEuroMutation>;
export type AddManualPointsByEuroMutationResult = Apollo.MutationResult<AddManualPointsByEuroMutation>;
export type AddManualPointsByEuroMutationOptions = Apollo.BaseMutationOptions<AddManualPointsByEuroMutation, AddManualPointsByEuroMutationVariables>;
export const RedeemPointsManuallyDocument = gql`
    mutation redeemPointsManually($amount: String!, $email: String!) {
  redeemPointsManually(addPointsInput: {amount: $amount, email: $email}) {
    id
    amount
  }
}
    `;
export type RedeemPointsManuallyMutationFn = Apollo.MutationFunction<RedeemPointsManuallyMutation, RedeemPointsManuallyMutationVariables>;

/**
 * __useRedeemPointsManuallyMutation__
 *
 * To run a mutation, you first call `useRedeemPointsManuallyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPointsManuallyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPointsManuallyMutation, { data, loading, error }] = useRedeemPointsManuallyMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRedeemPointsManuallyMutation(baseOptions?: Apollo.MutationHookOptions<RedeemPointsManuallyMutation, RedeemPointsManuallyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemPointsManuallyMutation, RedeemPointsManuallyMutationVariables>(RedeemPointsManuallyDocument, options);
      }
export type RedeemPointsManuallyMutationHookResult = ReturnType<typeof useRedeemPointsManuallyMutation>;
export type RedeemPointsManuallyMutationResult = Apollo.MutationResult<RedeemPointsManuallyMutation>;
export type RedeemPointsManuallyMutationOptions = Apollo.BaseMutationOptions<RedeemPointsManuallyMutation, RedeemPointsManuallyMutationVariables>;
export const RedeemPointsForProductDocument = gql`
    mutation redeemPointsForProduct($amount: String!, $email: String!) {
  redeemPointsForProduct(redeemPoints: {amount: $amount, email: $email}) {
    id
    amount
  }
}
    `;
export type RedeemPointsForProductMutationFn = Apollo.MutationFunction<RedeemPointsForProductMutation, RedeemPointsForProductMutationVariables>;

/**
 * __useRedeemPointsForProductMutation__
 *
 * To run a mutation, you first call `useRedeemPointsForProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPointsForProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPointsForProductMutation, { data, loading, error }] = useRedeemPointsForProductMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRedeemPointsForProductMutation(baseOptions?: Apollo.MutationHookOptions<RedeemPointsForProductMutation, RedeemPointsForProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemPointsForProductMutation, RedeemPointsForProductMutationVariables>(RedeemPointsForProductDocument, options);
      }
export type RedeemPointsForProductMutationHookResult = ReturnType<typeof useRedeemPointsForProductMutation>;
export type RedeemPointsForProductMutationResult = Apollo.MutationResult<RedeemPointsForProductMutation>;
export type RedeemPointsForProductMutationOptions = Apollo.BaseMutationOptions<RedeemPointsForProductMutation, RedeemPointsForProductMutationVariables>;
export const GetRewardListDocument = gql`
    query getRewardList($email: String!) {
  getRewardList(email: $email) {
    id
    label
    title
    description
    imageUrl
    pointCost
    isRedeemed
    redeemedAt
    redeemable
  }
}
    `;

/**
 * __useGetRewardListQuery__
 *
 * To run a query within a React component, call `useGetRewardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardListQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetRewardListQuery(baseOptions: Apollo.QueryHookOptions<GetRewardListQuery, GetRewardListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRewardListQuery, GetRewardListQueryVariables>(GetRewardListDocument, options);
      }
export function useGetRewardListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRewardListQuery, GetRewardListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRewardListQuery, GetRewardListQueryVariables>(GetRewardListDocument, options);
        }
export type GetRewardListQueryHookResult = ReturnType<typeof useGetRewardListQuery>;
export type GetRewardListLazyQueryHookResult = ReturnType<typeof useGetRewardListLazyQuery>;
export type GetRewardListQueryResult = Apollo.QueryResult<GetRewardListQuery, GetRewardListQueryVariables>;
export const UserRedeemRewardDocument = gql`
    mutation userRedeemReward($email: String!, $rewardId: String!) {
  userRedeemReward(email: $email, rewardId: $rewardId) {
    status
    message
  }
}
    `;
export type UserRedeemRewardMutationFn = Apollo.MutationFunction<UserRedeemRewardMutation, UserRedeemRewardMutationVariables>;

/**
 * __useUserRedeemRewardMutation__
 *
 * To run a mutation, you first call `useUserRedeemRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRedeemRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRedeemRewardMutation, { data, loading, error }] = useUserRedeemRewardMutation({
 *   variables: {
 *      email: // value for 'email'
 *      rewardId: // value for 'rewardId'
 *   },
 * });
 */
export function useUserRedeemRewardMutation(baseOptions?: Apollo.MutationHookOptions<UserRedeemRewardMutation, UserRedeemRewardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRedeemRewardMutation, UserRedeemRewardMutationVariables>(UserRedeemRewardDocument, options);
      }
export type UserRedeemRewardMutationHookResult = ReturnType<typeof useUserRedeemRewardMutation>;
export type UserRedeemRewardMutationResult = Apollo.MutationResult<UserRedeemRewardMutation>;
export type UserRedeemRewardMutationOptions = Apollo.BaseMutationOptions<UserRedeemRewardMutation, UserRedeemRewardMutationVariables>;
export const SearchUsersDocument = gql`
    query searchUsers($filter: String) {
  searchFilterUsers(filter: $filter) {
    email
    phoneNumber
    firstName
    lastName
  }
}
    `;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($emailAddress: String!, $firstName: String!, $lastName: String!, $password: String!, $phoneNumber: String!, $phoneNumberCountryCode: String!, $termsAgreed: Boolean!, $customerKey: String, $language: String, $paymentId: String) {
  createUser(
    createUserInput: {email: $emailAddress, firstName: $firstName, lastName: $lastName, password: $password, phoneNumber: $phoneNumber, phoneNumberCountryCode: $phoneNumberCountryCode, termsAgreed: $termsAgreed, cardAlias: $customerKey, language: $language, paymentId: $paymentId}
  ) {
    firstName
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *      phoneNumber: // value for 'phoneNumber'
 *      phoneNumberCountryCode: // value for 'phoneNumberCountryCode'
 *      termsAgreed: // value for 'termsAgreed'
 *      customerKey: // value for 'customerKey'
 *      language: // value for 'language'
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const SendRegisterSmsDocument = gql`
    mutation sendRegisterSMS($to: String!, $paymentId: String, $phoneNumberCountryCode: String) {
  sendRegisterSMS(
    sendRegisterSMSInput: {to: $to, paymentId: $paymentId, phoneNumberCountryCode: $phoneNumberCountryCode}
  ) {
    smsId
  }
}
    `;
export type SendRegisterSmsMutationFn = Apollo.MutationFunction<SendRegisterSmsMutation, SendRegisterSmsMutationVariables>;

/**
 * __useSendRegisterSmsMutation__
 *
 * To run a mutation, you first call `useSendRegisterSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRegisterSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRegisterSmsMutation, { data, loading, error }] = useSendRegisterSmsMutation({
 *   variables: {
 *      to: // value for 'to'
 *      paymentId: // value for 'paymentId'
 *      phoneNumberCountryCode: // value for 'phoneNumberCountryCode'
 *   },
 * });
 */
export function useSendRegisterSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendRegisterSmsMutation, SendRegisterSmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendRegisterSmsMutation, SendRegisterSmsMutationVariables>(SendRegisterSmsDocument, options);
      }
export type SendRegisterSmsMutationHookResult = ReturnType<typeof useSendRegisterSmsMutation>;
export type SendRegisterSmsMutationResult = Apollo.MutationResult<SendRegisterSmsMutation>;
export type SendRegisterSmsMutationOptions = Apollo.BaseMutationOptions<SendRegisterSmsMutation, SendRegisterSmsMutationVariables>;
export const CheckTokenDocument = gql`
    query checkToken($email: String!) {
  verifyUserToken(email: $email) {
    isTokenValid
    Role
  }
}
    `;

/**
 * __useCheckTokenQuery__
 *
 * To run a query within a React component, call `useCheckTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckTokenQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckTokenQuery(baseOptions: Apollo.QueryHookOptions<CheckTokenQuery, CheckTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckTokenQuery, CheckTokenQueryVariables>(CheckTokenDocument, options);
      }
export function useCheckTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckTokenQuery, CheckTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckTokenQuery, CheckTokenQueryVariables>(CheckTokenDocument, options);
        }
export type CheckTokenQueryHookResult = ReturnType<typeof useCheckTokenQuery>;
export type CheckTokenLazyQueryHookResult = ReturnType<typeof useCheckTokenLazyQuery>;
export type CheckTokenQueryResult = Apollo.QueryResult<CheckTokenQuery, CheckTokenQueryVariables>;
export const GetDataFromSearchedUserDocument = gql`
    query getDataFromSearchedUser($email: String!) {
  getDataFromSearchedUser(email: $email) {
    countOfEcomPayments
    countOfPOSPayments
    user {
      firstName
      lastName
      email
      phoneNumber
    }
    totalCountOfTransactions
    totalAmountOfPoints
  }
}
    `;

/**
 * __useGetDataFromSearchedUserQuery__
 *
 * To run a query within a React component, call `useGetDataFromSearchedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataFromSearchedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataFromSearchedUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetDataFromSearchedUserQuery(baseOptions: Apollo.QueryHookOptions<GetDataFromSearchedUserQuery, GetDataFromSearchedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataFromSearchedUserQuery, GetDataFromSearchedUserQueryVariables>(GetDataFromSearchedUserDocument, options);
      }
export function useGetDataFromSearchedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataFromSearchedUserQuery, GetDataFromSearchedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataFromSearchedUserQuery, GetDataFromSearchedUserQueryVariables>(GetDataFromSearchedUserDocument, options);
        }
export type GetDataFromSearchedUserQueryHookResult = ReturnType<typeof useGetDataFromSearchedUserQuery>;
export type GetDataFromSearchedUserLazyQueryHookResult = ReturnType<typeof useGetDataFromSearchedUserLazyQuery>;
export type GetDataFromSearchedUserQueryResult = Apollo.QueryResult<GetDataFromSearchedUserQuery, GetDataFromSearchedUserQueryVariables>;
export const GetUserOverviewDataDocument = gql`
    query getUserOverviewData($email: String!) {
  getUserOverviewData(email: $email) {
    firstName
    lastName
    phoneNumber
    totalAmountOfPoints
    language
    Points {
      id
      amount
      validFrom
      createdAt
    }
  }
}
    `;

/**
 * __useGetUserOverviewDataQuery__
 *
 * To run a query within a React component, call `useGetUserOverviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOverviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOverviewDataQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserOverviewDataQuery(baseOptions: Apollo.QueryHookOptions<GetUserOverviewDataQuery, GetUserOverviewDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserOverviewDataQuery, GetUserOverviewDataQueryVariables>(GetUserOverviewDataDocument, options);
      }
export function useGetUserOverviewDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserOverviewDataQuery, GetUserOverviewDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserOverviewDataQuery, GetUserOverviewDataQueryVariables>(GetUserOverviewDataDocument, options);
        }
export type GetUserOverviewDataQueryHookResult = ReturnType<typeof useGetUserOverviewDataQuery>;
export type GetUserOverviewDataLazyQueryHookResult = ReturnType<typeof useGetUserOverviewDataLazyQuery>;
export type GetUserOverviewDataQueryResult = Apollo.QueryResult<GetUserOverviewDataQuery, GetUserOverviewDataQueryVariables>;
export const UpdateUserAccountDocument = gql`
    mutation updateUserAccount($updateUserAccountInput: UpdateUserAccountInput!, $userEmailFromClient: String!) {
  updateUserAccountData(
    updateUserAccountInput: $updateUserAccountInput
    userEmailFromClient: $userEmailFromClient
  ) {
    email
    firstName
    isEmailChanged
    language
  }
}
    `;
export type UpdateUserAccountMutationFn = Apollo.MutationFunction<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      updateUserAccountInput: // value for 'updateUserAccountInput'
 *      userEmailFromClient: // value for 'userEmailFromClient'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(UpdateUserAccountDocument, options);
      }
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>;
export type UpdateUserAccountMutationResult = Apollo.MutationResult<UpdateUserAccountMutation>;
export type UpdateUserAccountMutationOptions = Apollo.BaseMutationOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($userEmail: String!) {
  deleteUser(userEmail: $userEmail) {
    email
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetPosListDocument = gql`
    query getPosList($adminEmail: String) {
  getPosList(adminEmail: $adminEmail) {
    posId
    name
  }
}
    `;

/**
 * __useGetPosListQuery__
 *
 * To run a query within a React component, call `useGetPosListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosListQuery({
 *   variables: {
 *      adminEmail: // value for 'adminEmail'
 *   },
 * });
 */
export function useGetPosListQuery(baseOptions?: Apollo.QueryHookOptions<GetPosListQuery, GetPosListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPosListQuery, GetPosListQueryVariables>(GetPosListDocument, options);
      }
export function useGetPosListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPosListQuery, GetPosListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPosListQuery, GetPosListQueryVariables>(GetPosListDocument, options);
        }
export type GetPosListQueryHookResult = ReturnType<typeof useGetPosListQuery>;
export type GetPosListLazyQueryHookResult = ReturnType<typeof useGetPosListLazyQuery>;
export type GetPosListQueryResult = Apollo.QueryResult<GetPosListQuery, GetPosListQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    