export const devCloud = {
  apiKey: "AIzaSyAj002GU9pxI7lnPU_f7qMDgdKb14NdphQ",
  authDomain: "lagardere-dev.firebaseapp.com",
  projectId: "lagardere-dev",
  storageBucket: "lagardere-dev.appspot.com",
  messagingSenderId: "1032400304786",
  appId: "1:1032400304786:web:19db2c7e062505df9b844b",
  measurementId: "G-MP5VP0W75H",
};
export const prodCloud = {
  apiKey: "AIzaSyAfFuxvrHoM98j4hN_SP17btZOP6xYq2hU",
  authDomain: "lagardere-prod.firebaseapp.com",
  projectId: "lagardere-prod",
  storageBucket: "lagardere-prod.appspot.com",
  messagingSenderId: "1012745373651",
  appId: "1:1012745373651:web:0dfadfe820ebd7726ed126",
  measurementId: "G-1T2V476VCQ",
};

export const localDev = {
  apiKey: "AIzaSyBnlj9dU4Kz6MRL_IKK-TPhoh_jWAkfeOs",
  authDomain: "lagardere-local-dev.firebaseapp.com",
  projectId: "lagardere-local-dev",
  storageBucket: "lagardere-local-dev.appspot.com",
  messagingSenderId: "919328399190",
  appId: "1:919328399190:web:3b940b92d362fd6489c0f7",
  measurementId: "G-9LBV3TJMM0"
};