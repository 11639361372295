import React from "react";

import InitialState from "./InitialState/InitialState";
import SuccessResponse from "./SuccessResponse/SuccessResponse";
import UnknownResponse from "./Unknown/UnknownResponse";
import UnregisteredResponse from "./Unregistered/UnregisteredResponse";

const ClientInformation = (props: { memberInformation?: any }) => {
  const { memberInformation } = props;

  // When terminal is triggered, we receive status "pending" from backend. Show
  // loading text and spinner in InitialState component
  if (memberInformation?.status === "pending")
    return (
      <div className="flex rounded-3 bg-light" style={{ height: "100%" }}>
        <InitialState loading />
      </div>
    );

  const orderIdBlock = memberInformation?.transactionId ? (
    <p
      className={`d-block text-center text-dark bottom-0 `}
      style={{ width: "100%" }}
    >
      OrderID: {memberInformation?.transactionId} <br />
      <span className="text-xs">
        {new Date(Number(memberInformation?.createdAt)!).toLocaleString(
          "nl-NL",
          {
            dateStyle: "medium",
            timeStyle: "medium",
          }
        )}
      </span>
    </p>
  ) : null;

  return (
    <div
      className="flex rounded-3"
      style={{ height: orderIdBlock ? "auto" : "200px", width: "100%" }}
    >
      {/* Inital state. Waiting for terminal to be triggered */}
      {!memberInformation && <InitialState loading={false} />}

      {/* User is found and recognized */}
      {memberInformation?.userData && (
        <SuccessResponse
          orderIdBlock={orderIdBlock}
          memberInformation={memberInformation}
        />
      )}

      {/* Card is used multiple times, but not registered to user yet */}
      {!memberInformation?.userData &&
        memberInformation?.countOfPOSPayments > 1 && (
          <UnregisteredResponse
            orderIdBlock={orderIdBlock}
            totalCountOfPayments={memberInformation.totalCountOfTransactions}
          />
        )}

      {/* First time payment */}
      {!memberInformation?.userData &&
        memberInformation?.countOfPOSPayments === 1 && (
          <UnknownResponse orderIdBlock={orderIdBlock} />
        )}
    </div>
  );
};

export default ClientInformation;

ClientInformation.defaultProps = {
  memberInformation: undefined,
};
