import React from "react";

import Ylogo from "../../../assets/Ylogo.png";
import "./InitialState.scss";

const InitialState = (props: { loading: boolean }) => {
  const { loading } = props;

  return (
    <div
      id="initialState-block"
      className="d-flex justify-content-center align-items-center"
      style={{ whiteSpace: "nowrap" }}
    >
      <div className="">
        <div className="my-4">
          <p className="fs-5 text-center m-0">wachten op betaling…</p>
        </div>
        {loading && (
          <div className="d-flex justify-content-center">
            <div className="logo-loading">
              <img src={Ylogo} alt="logo" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InitialState;
