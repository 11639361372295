import React from "react";

import { useGetUserOverviewDataQuery } from "../../graphql/__generated__/graphql_types";

import "react-toggle/style.css";
import "./RedeemRewardInfo.scss";

const RedeemRewardInfo = ({
  customerData,
}: // amountOfPoints,
{
  customerData: {
    email: string;
    firstName: string;
    lastName: string;
    totalAmountOfPoints: number;
  };
  // amountOfPoints: string;
}) => {
  const { data } = useGetUserOverviewDataQuery({
    variables: {
      email: customerData.email!,
    },
  });

  return (
    <div id="redeemRewardInfo" className="">
      <div className="d-flex flex-wrap justify-content-center align-items-center Div-centers-reedem">
        <div className="cardBox mx-0 storecard addpoints mr-0 mr-md-4">
          <div className="cardBox-head">
            <h1 className="text-main-text pt-2">{`${customerData?.firstName} ${customerData?.lastName}`}</h1>
            <p className="text-center"> Accumulated points:</p>
            <h2 className="text-center text-main-text">
              {data?.getUserOverviewData?.totalAmountOfPoints}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemRewardInfo;
