import React, { useState } from "react";

import { AiOutlineUserAdd } from "react-icons/ai";
import Modal from "react-modal";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { toast } from "react-toastify";

import { useSendRegisterSmsMutation } from "../../graphql/__generated__/graphql_types";


import "./CreateUserModal.scss";

const CreateUserModal = (props: {
  isOpen: boolean;
  closeModal: () => void;
  phoneNumber: string;
  paymentId: string | undefined | null;
  reset: () => void;
}) => {
  const { isOpen, closeModal, phoneNumber, paymentId, reset } = props;
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState(`+31${phoneNumber}`);
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState("NL");

  const handlePhoneChange = (phone: string, country: CountryData) => {
    setMobilePhoneNumber(`+${phone}`);
    setPhoneNumberCountryCode(country.countryCode.toUpperCase());
  };

  const [sendSMS] = useSendRegisterSmsMutation();

  const sendRegisterSMS = async () => {
    // TODO: Handle flow if no cardAlias is selected. IE if no order is selected, but directly searching

    await sendSMS({
      variables: {
        paymentId: paymentId as string,
        to: mobilePhoneNumber,
        phoneNumberCountryCode,
      },
    }).then(
      () => {
        reset();
        closeModal();
        toast.success("De uitnodiging is verzonden via SMS!");
      },
      () => {
        toast.error("Telefoonnummer is niet valide.");
        closeModal();
      }
    );
  };

  Modal.setAppElement("#root");
  return (
    <Modal
      id="createUserModal"
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={closeModal}
      className="col-12 d-flex justify-content-center align-items-center"
      style={{ overlay: { background: "rgba(0,0,0, 0.5)", zIndex: 2000 } }}
    >
      <div className="rounded-3 col-11 col-md-10 col-lg-8">
        <div className="top-block col-12 d-flex justify-content-center align-items-center flex-column">
          <div className="information-icon-block">
            <div className="information-icon rounded-circle">
              <AiOutlineUserAdd color="white" size={30} />
            </div>
          </div>
          <div className="title text-center d-flex justify-content-center align-items-center">
            <p className="m-0">Koppel kaart aan nieuwe gebruiker</p>
          </div>
        </div>

        <div className="bottom-block text-center">
          <div className="py-5">
            U staat op het punt om een gebruiker uit te nodigen met
            telefoonnummer:
            <div className="input-group has-validation">
              <PhoneInput
                inputProps={{
                  id: "input-phoneNumber",
                  name: "mobilePhoneNumber",
                }}
                inputStyle={{ width: "100%" }}
                country="nl"
                countryCodeEditable={false}
                enableSearch
                onChange={(phone: string, country: CountryData) => {
                  handlePhoneChange(phone, country);
                }}
                value={mobilePhoneNumber}
              />
              <div className="invalid-feedback">...</div>
            </div>
            De klant krijgt een verificatie sms en bij een correcte afhandeling
            van de sms is de kaart gekoppeld.
          </div>

          <div className="pb-5 col-12 d-flex justify-content-center align-items-center">
            <button
              className="cancel-btn mx-2"
              type="button"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="confirm-btn mx-2"
              type="button"
              onClick={sendRegisterSMS}
            >
              Bevestig
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUserModal;
