import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonDE from "./locales/de/common.json";
import commonEN from "./locales/en/common.json";
import commonNL from "./locales/nl/common.json";

export const defaultNS = "common";
export const resources = {
  en: {
    common: commonEN,
  },
  nl: {
    common: commonNL,
  },
  de: {
    common: commonDE,
  },
} as const;

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

// https://react.i18next.com/latest/typescript
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: localStorage.getItem("lng") || "nl",
    ns: ["common"],
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format) => {
        if (format === "uppercase") return value.toUpperCase();
        if (format === "lowercase") return value.toLowerCase();
        if (format === "capitalize")
          return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      },
    },
    defaultNS,
    resources,
    react: {
      bindI18n: false
    }
  });

export default i18n;
