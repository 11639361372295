import React from "react";

import logo from "../assets/YOURLOGO.png";
import BulletsInformation from "../components/BulletsInformation/BulletsInformation";
import Login from "../components/Login/Login";
import "./LoginPage.scss";

const LoginPage = () => (
  <>
    <div id="loginPage-div">
      <div className="login-container container d-flex flex-column justify-content-center align-items-center">
        <div className="logo-img-block-sm d-block">
          <img className="logo-img" alt="logo" src={logo} />
        </div>
        <div className="main-block row">
          <div className="col-lg-6 d-lg-flex d-none">
            <BulletsInformation />
          </div>
          <div className="login-block col-12 col-lg-6 d-lg-flex flex-column bg-light px-3 py-5 px-md-5">
            <div className="col-12 text-center">
              <p className="fs-3 fw-bold">LOGIN</p>
              <p className="fs-6 text-muted">Enter your details for login</p>
            </div>
            <div className="login-component">
              <Login />
              <div className="d-block d-lg-none pt-2">
                <BulletsInformation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default LoginPage;
