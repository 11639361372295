import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";

import NavbarComponent from "../components/NavbarComponent/NavbarComponent";
import RedeemRewardInfo from "../components/RedeemRewardInfo/RedeemRewardInfo";
import Rewards from "../components/Rewards/Rewards";
// import { useQuery } from "../graphql/__generated__/graphql_NavbarComponenttypes";

import "./RedeemRewardsPage.scss";

const initialState = {
  email: "",
  firstName: "",
  lastName: "",
  totalAmountOfPoints: 0,
};

const RedeemRewardsPage = () => {
  const [customerData, setCustomerData] = useState(initialState);

  const history = useHistory();
  const { state } = useHistory().location as any;

  useEffect(() => {
    if (!state?.customerData?.email) history.push("/interface");

    if (state?.customerData) {
      setCustomerData({
        ...state.customerData,
        totalAmountOfPoints: state.totalAmountOfPoints,
      });
    }
  }, [history, state.customerData, state.totalAmountOfPoints]);

  return (
    <div id="redeemRedwards">
      <NavbarComponent backIcon posOption={false} />
      <div className="main-block row col-12 pb-5 mx-0 d-flex justify-content-center justify-content-md-evenly align-items-center">
        <div className="block col-10 col-md-5 mt-5 mt-lg-0">
          {customerData?.email && (
            <RedeemRewardInfo customerData={customerData} />
          )}
        </div>
        <div className="block col-10 col-md-5 mt-5 mt-lg-0 d-flex flex-column justify-content-between">
          <Rewards />
        </div>
      </div>
    </div>
  );
};

export default RedeemRewardsPage;
