/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { ToastContainer } from "react-toastify";

import ApolloClient from "./Apollo/ApolloProvider";
import Loading from "./components/Loading/Loading";
import firebase from "./config/firebase";
import Routes from "./Routes";
import { AppContext } from "./utils/contextLib";
import "./App.scss";
// import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [firebaseUser, loading] = useAuthState(firebase.auth());
  const [isAuthenticated, userHasAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const { pathname } = window.location;
    console.log({ pathname });
    if (pathname === "/interface") {
      window.location.href = "https://spaar.relaywinkels.nl/cashier";
    } else {
      window.location.href = "https://spaar.relaywinkels.nl";
    }
  }, []);

  useEffect(() => {
    if (firebaseUser) {
      userHasAuthenticated(true);
    }
  }, [firebaseUser]);

  if (loading) return <Loading />;
  // if (error) return <ErrorPage />;

  return (
    <AppContext.Provider
      value={{ isAuthenticated, userHasAuthenticated, firebaseUser }}
    >
      <ApolloClient>
        <ToastContainer />
        <Routes />
      </ApolloClient>
    </AppContext.Provider>
  );
}

export default App;
